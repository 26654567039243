import { useEffect, useState } from 'react';
import {
  useActiveAccount,
  useCurrentUser,
  useDispatch,
  useUnisonProjectTasks,
} from '@/store/useStore';
import { setUnisonProjectTasks as dispatchSetUnisonProjectTasks } from '@/store/slices/unisonProjectTasksSlice';
import EmptyMessage from '../EmptyMessage';
import OverviewCard from '../OverviewCard';
import UnisonProjectTasksList from '@/components/UnisonProjectTasksList';
import { useToggle } from '@/hooks/useToggle';
import { usePath } from '@/hooks/usePath';
import { useAppState } from '@/providers/AppStateProvider';
import { formatNumber } from '@/utils/strings';
import { classes, fetchTasks, filterTasks } from './utils';

export default function TasksCard() {
  const dispatch = useDispatch();
  const { navigate } = usePath();
  const { onApiError } = useAppState();
  const currentUser = useCurrentUser();
  const activeAccount = useActiveAccount();
  const [tasksCount, setTasksCount] = useState<number>(0);
  const [isFetchingData, toggleFetchingData] = useToggle(true);
  const tasks = useUnisonProjectTasks();

  useEffect(() => {
    if (activeAccount && currentUser) fetchData();
  }, [currentUser, activeAccount]);

  const fetchData = async () => {
    try {
      toggleFetchingData(true);
      const data = await fetchTasks(activeAccount!.accountID, currentUser!.id);
      dispatch(dispatchSetUnisonProjectTasks(data.results));
      setTasksCount(data.pagination.rowCount);
      toggleFetchingData(false);
    } catch (err) {
      toggleFetchingData(false);
      onApiError(err, 'Error fetching tasks', () => fetchData());
    }
  };

  const renderSubtitle = () => {
    if (isFetchingData) return null;
    if (tasksCount === 0) return 'No open tasks';
    return `${formatNumber(tasksCount)} total`;
  };

  const filteredTasks = filterTasks(currentUser!.id, tasks);

  return (
    <OverviewCard
      title="My Tasks"
      subtitle={renderSubtitle()}
      onViewAll={() => navigate('/dashboard/tasks')}>
      {isFetchingData || filteredTasks.length > 0 ? (
        <UnisonProjectTasksList
          className={classes.list}
          isFetchingData={isFetchingData}
          unisonProjectTasks={filteredTasks}
        />
      ) : (
        <EmptyMessage>No open tasks.</EmptyMessage>
      )}
    </OverviewCard>
  );
}
