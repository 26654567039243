import { useEffect, useState } from 'react';
import {
  useActiveAccount,
  useDispatch,
  usePracticeUnisonProjects,
} from '@/store/useStore';
import EmptyMessage from '../EmptyMessage';
import OverviewCard from '../OverviewCard';
import ProjectsList from '@/components/ProjectsList';
import { setUnisonProjects as dispatchSetUnisonProjects } from '@/store/slices/unisonProjectsSlice';
import { useToggle } from '@/hooks/useToggle';
import { useAppState } from '@/providers/AppStateProvider';
import { usePath } from '@/hooks/usePath';
import { formatNumber } from '@/utils/strings';
import {
  classes,
  fetchActiveProjects,
  filterProjects,
  STATUS_FILTER,
} from './utils';

export default function ProjectsCard() {
  const dispatch = useDispatch();
  const { navigate } = usePath();
  const { onApiError } = useAppState();
  const activeAccount = useActiveAccount();
  const [projectsCount, setProjectsCount] = useState(0);
  const [isFetchingData, toggleFetchingData] = useToggle(true);
  const projects = usePracticeUnisonProjects();

  useEffect(() => {
    if (activeAccount) {
      setProjectsCount(0);
      fetchData();
    }
  }, [activeAccount]);

  const fetchData = async () => {
    try {
      toggleFetchingData(true);
      const data = await fetchActiveProjects();
      dispatch(dispatchSetUnisonProjects(data.results));
      setProjectsCount(data.pagination.rowCount);
      toggleFetchingData(false);
    } catch (err) {
      toggleFetchingData(false);
      onApiError(err, 'Error fetching cases', () => fetchData());
    }
  };

  const handleViewAll = () => {
    navigate('/dashboard/projects/', {
      filters: JSON.stringify([STATUS_FILTER]),
    });
  };

  const renderSubtitle = () => {
    if (isFetchingData) return null;
    if (projectsCount === 0) return 'No active cases';
    return `${formatNumber(projectsCount)} total`;
  };

  const filteredProjects = filterProjects(projects || []);

  return (
    <OverviewCard
      title="Active Cases"
      subtitle={renderSubtitle()}
      onViewAll={handleViewAll}>
      {isFetchingData || filteredProjects.length > 0 ? (
        <ProjectsList
          isFetchingData={isFetchingData}
          size="sm"
          className={classes.list}
          projects={filteredProjects}
        />
      ) : (
        <EmptyMessage>No active cases.</EmptyMessage>
      )}
    </OverviewCard>
  );
}
